<template>
  <auth-layout>
    <p class="block font-bold mb-4 pr-20">Reset je wachtwoord</p>
    <form class="w-full">
      <div>
        <label class="block text-sm font-bold mb-2" for="email">
          Wachtwoord
        </label>
        <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !password ? 'border-primary' : '']" id="password" type="password" placeholder="Wachtwoord" v-model="password" required>
      </div>
      <div>
        <label class="block text-sm font-bold mb-2 mt-3" for="email">
          Bevestig wachtwoord
        </label>
        <input class="border rounded w-full py-2 px-3 leading-tight text-black bg-gray-100" :class="[error && !confirmPassword ? 'border-primary' : '']" id="confirmPassword" type="password" placeholder="Bevestig wachtwoord" v-model="confirmPassword" required>
        <p v-if="confirmPassword" class="text-primary text-sm font-semibold">{{ error }}</p>
      </div>
      <div class="rounded-lg text-white px-4 py-3 text-sm cursor-pointer bg-primary w-44 text-center mt-4" @click="resetPassword()">
        Wachtwoord instellen
      </div>
      <router-link to="/login"><p class="flex-none cursor-pointer pt-4">Login</p></router-link>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from '@/components/layouts/Auth'

export default {
  title: 'Wachtwoord resetten',
  components: {
    AuthLayout
  },
  data () {
    return {
      password: null,
      confirmPassword: null,
      error: null
    }
  },
  mounted () {
    this.$store.dispatch('logout')
  },
  methods: {
    resetPassword () {
      const passwordToken = this.$route.query.token
      const password = this.password
      const confirmPassword = this.confirmPassword
      if (!password || !confirmPassword) return this.setError('Niet alles is juist ingevuld')
      if (password !== confirmPassword) return this.setError('Wachtwoorden komen niet overeen')
      this.$store
        .dispatch('resetPassword', { password, passwordToken })
        .then(() => this.$router.push('/login'))
        .catch(() => this.setError('Er is iets fout gegaan'))
    },
    setError (data) {
      this.error = data
    }
  }
}
</script>
